import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LoanProposalModel } from '../../models/InitDetails.model';
import { CalculadoraService } from '../../services/calculadora.service';

@Component({
  selector: 'app-slider',
  templateUrl: './app-slider.component.html',
  styleUrls: ['./app-slider.component.scss']
})
export class AppSliderComponent implements OnInit {

  calculateValues = {
    amount: 0,
    minAmount: 0,
    maxAmount: 0,
    stepAmount: 1
  };

  calculateValues2 = {
    amount: 0,
    minAmount: 0,
    maxAmount: 0,
    stepAmount: 12
  };

  initData;

  outOfAmountBounds = false;
  months = [];
  selectedMonth: number;

  @Input() initData$: Observable<LoanProposalModel>;
  @Input() accesstoken: string;

  @Output() outputData = new EventEmitter<any>();
  @Output() disableButton = new EventEmitter<boolean>();

  constructor(
    private calculadoraService: CalculadoraService
  ) {}

  ngOnInit() {
    this.initData$
    .pipe(
      filter(data => !!data)
    )
    .subscribe( (data: LoanProposalModel) => {
      this.initData = {...data};

      this.calculateValues = {...this.calculateValues,
        ...{
          amount: data.Amount,
          minAmount: data.Amount_Min,
          maxAmount: data.Amount_Max,
          stepAmount: data.Length_Amount
        }
      };

      this.calculateValues2 = {...this.calculateValues2,
        ...{
          amount: data.Deadlines,
          minAmount: data.Deadline_Min,
          maxAmount: data.Deadline_Max,
          stepAmount: data.Length_Deadlines
        }
      };

    });
  }

  calculateMonthsRange(values) {
    const numberOfElements = (values.maxAmount - values.minAmount) / values.stepAmount;
    let max = values.maxAmount;
    this.months = new Array(numberOfElements);
    for (let i = this.months.length; i >=0 ; i--) {
      this.months[i] = max;
      max = max - values.stepAmount;
    }
  }

  calculatePreviousMonthPosition(){
    if (!!this.selectedMonth) {
      const isPresent = this.months.find( month => month === this.selectedMonth);
      this.selectedMonth =
          this.selectedMonth > this.months[this.months.length -1] ? this.months[this.months.length -1] :
            (this.selectedMonth < this.months[0] ? this.months[0] : isPresent);
      this.initData.Deadlines = this.selectedMonth ? this.selectedMonth : this.initData.Deadline_Max;
    }
  }

  resetValues() { // work wround due to a primeng slider bug which doesn't re-render months changes
    const currentValues2 = {...this.calculateValues2};
    this.calculateValues2 = null;
    this.calculateValues = {...this.calculateValues,
      ...{
        amount: this.initData.Amount,
        minAmount: this.initData.Amount_Min,
        maxAmount: this.initData.Amount_Max,
        stepAmount: this.initData.Length_Amount
      }
    };

    setTimeout( () => {
      this.calculateValues2 = {...currentValues2,
        ...{
          amount: this.initData.Deadlines,
          minAmount: this.initData.Deadline_Min,
          maxAmount: this.initData.Deadline_Max,
          stepAmount: this.initData.Length_Deadlines
        }
      };
    }, 0)
  }

  onDisableButton(state) {
    this.disableButton.emit(state);
  }

  calculate(ammountValue: number, isQuantity: boolean) {

    if (isQuantity) {
      this.initData.Amount = ammountValue;
    } else {
      this.initData.Deadlines = ammountValue;
    }

    this.calculadoraService.getCalculate(
      this.accesstoken,
      this.initData
    ).pipe(
      filter( data => !!data)
    ).subscribe( (data: LoanProposalModel)  => {
      this.initData = {...data};

      this.resetValues();

      this.calculateMonthsRange({
        minAmount: data.Deadline_Min,
        maxAmount: data.Deadline_Max,
        stepAmount: data.Length_Deadlines
      });

      this.calculatePreviousMonthPosition();

      this.outputData.emit({...this.initData});
    });
  }

  broadCastAmountValue(event) {
    this.calculate(event, true);
  }

  broadCastMonthsValue(event) {
    this.selectedMonth = event;
    this.calculate(event, false);
  }
}
