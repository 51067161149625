import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoanProposalModel } from './models/InitDetails.model';
import { AmortizationTableModel } from './models/AmortizationTable.model';
import { CalculadoraService } from './services/calculadora.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-calculadora-site',
  templateUrl: './app-calculadora-site.component.html',
  styleUrls: ['./app-calculadora-site.component.scss']
})
export class AppCalculadoraSiteComponent implements OnInit {

  accesstoken = '12345'; // TODO: Remove & change by real data

  initData$ = new BehaviorSubject<LoanProposalModel>(null);
  calculosData$ = new BehaviorSubject<LoanProposalModel>(null);
  sliderValues;
  ur;
  initData: LoanProposalModel;
  viewListData = false;
  listData: AmortizationTableModel;
  amountOutOfBounds = false;
  disableButton = false;

  constructor(
    private calculadoraService: CalculadoraService,
  ) {

  }

  ngOnInit(){
    this.ur = this.getQueryParamValue('token');
    // var ur = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    this.calculadoraService.initDetails(this.ur)
      .subscribe( (data: LoanProposalModel) => {
        this.initData$.next({...data});
        this.initData = {...data};
        this.calculosData$.next(this.initData);
    });

    this.calculadoraService.amountOutOfBounds$
      .subscribe ( amountOutOfBounds => {
        this.amountOutOfBounds = amountOutOfBounds;
      });
  }

  getQueryParamValue(paramName: string) {
    const url = window.location.href;
    let paramValue = '';
    if(url.indexOf('?') !== 0) {
      const params = new HttpParams({fromString: url.split('?')[1]});
      paramValue = params.get(paramName);
    }
    return paramValue;
  }

  onDisableButton(state) {
    this.disableButton = state;
  }

  setResume(calculatedValues) {
    this.initData = calculatedValues;
    this.calculosData$.next(this.initData);
    this.disableButton = false;
  }

  showListData(event) {
    this.toggleViewListData();
  }

  hideListData(event) {
    this.viewListData = event;
    this.sliderValues = this.sliderValues;
  }

  toggleViewListData() {
    this.viewListData = !this.viewListData;
  }
  loanRequest() {
    window.open(environment.urlRedirect + this.initData.Url + '/calculate');
  }
}
