import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { AppCalculadoraSiteModule } from './calculadora-site/app-calculadora-site.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppCalculadoraSiteModule,
    RouterModule.forRoot([], { enableTracing: true }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/pluginwp' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
