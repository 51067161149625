import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoanProposalModel } from '../models/InitDetails.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class CalculadoraService {
    constructor(
        private http: HttpClient
    ) { }

    amountOutOfBounds$ = new BehaviorSubject<boolean>(false);

    initDetails(ur: any): Observable<LoanProposalModel> {
        return this.http
    // .get<LoanProposalModel>(environment.host +  environment.api +'/OrigenByToken/' + ur);
    .get<LoanProposalModel>(environment.host +  environment.api +'/InitDetails/');
}

    getCalculate(accesstoken, request: LoanProposalModel): Observable<LoanProposalModel> {
        const params = {
            request
        };
        const headers = { // TODO: Remove & replace by token interceptor at main project
            headers: new HttpHeaders({authorization: `Bearer ${accesstoken}`})
        };
        // return this.http.post<LoanProposalModel>(environment.host +  environment.api +'/Calculate/', request);
        return this.http.post<LoanProposalModel>(environment.host +  environment.api +'/GetCalculate/', request);
    }
}
